var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"eventcontent"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12 is-10-desktop is-offset-1-desktop"},[(_vm.isLoadingEvent)?_c('div',{staticClass:"eventbutton mb-5"},[_c('b-skeleton',{attrs:{"height":"30px","width":"30%"}}),_c('b-skeleton',{attrs:{"height":"20px","width":"15%"}})],1):_vm._e(),(_vm.isLoadingEvent)?_c('div',{staticClass:"eventbutton mb-5"},[_c('b-skeleton',{attrs:{"height":"30px","width":"15%"}}),_c('b-skeleton',{attrs:{"height":"20px","width":"12%"}})],1):_vm._e(),(_vm.isLoadingEvent)?_c('div',{staticClass:"eventbutton mb-5"},[_c('b-skeleton',{attrs:{"height":"30px","width":"25%"}}),_c('b-skeleton',{attrs:{"height":"20px","width":"18%"}})],1):_vm._e(),(_vm.isLoadingEvent)?_c('div',{staticClass:"eventbutton mb-5"},[_c('b-skeleton',{attrs:{"height":"30px","width":"19%"}}),_c('b-skeleton',{attrs:{"height":"20px","width":"15%"}})],1):_vm._e(),_c('transition-group',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn animate__slow","leave-active-class":"animate__animated animate__fadeOut animate__slow"}},_vm._l((_vm.data.results),function(event){return _c('div',{key:event.id,staticClass:"event"},[_c('div',{staticClass:"eventbutton mb-5",on:{"click":function($event){return _vm.openModal(event)}}},[(event.properties.WebsiteHeadline.rich_text.length != 0)?_c('h2',{staticClass:"is-size-3 is-size-4-mobile mb-3"},[_vm._v(" "+_vm._s(event.properties.WebsiteHeadline.rich_text[0].plain_text)+" ")]):_vm._e(),_c('p',[(
                  event.properties.Datum.date.end == null &&
                  event.properties.Datum.date.start.length == 10
                )?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"DD.MM.YYYY"))+" ")]):(
                  event.properties.Datum.date.end == null &&
                  event.properties.Datum.date.start.length != 10
                )?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"DD.MM.YYYY"))+" ab "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"HH:mm"))+" ")]):(
                  event.properties.Datum.date.end != null &&
                  event.properties.Datum.date.start.length != 10 &&
                  event.properties.Datum.date.start.slice(0, -19) ==
                    event.properties.Datum.date.end.slice(0, -19)
                )?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"DD.MM.YYYY"))+" von "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"HH:mm"))+" bis "+_vm._s(_vm._f("moment")(event.properties.Datum.date.end,"HH:mm"))+" ")]):_vm._e(),(
                  event.properties.Datum.date.end != null &&
                  event.properties.Datum.date.end.length == 10
                )?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"DD.MM.YYYY"))+" bis "+_vm._s(_vm._f("moment")(event.properties.Datum.date.end,"DD.MM.YYYY"))+" ")]):_vm._e(),(
                  event.properties.Datum.date.end != null &&
                  event.properties.Datum.date.end.length != 10 &&
                  event.properties.Datum.date.start.slice(0, -19) !=
                    event.properties.Datum.date.end.slice(0, -19)
                )?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"DD.MM.YYYY"))+", "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"HH:mm"))+" bis "+_vm._s(_vm._f("moment")(event.properties.Datum.date.end,"DD.MM.YYYY"))+", "+_vm._s(_vm._f("moment")(event.properties.Datum.date.end,"HH:mm"))+" ")]):_vm._e(),(event.properties.WebsiteOrt.rich_text.length != 0)?_c('span',{staticClass:"is-size-6 eventtext"},[_vm._v(" | "+_vm._s(event.properties.WebsiteOrt.rich_text[0].plain_text)+" ")]):_vm._e()])])])}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }