<template>
  <div class="notioncontent">
    <div class="container content">
      <Meta :title="title" :description="description" />
      <FullPageContent :notiondata="data" />
      <BlockContent @notiondata="apidata" :notionpage="NotionPageID" />
      <EventContent
        @eventmodaldata="eventmodal"
        :notionevent1="NotionEventID1"
        :notionevent2="NotionEventID2"
      />
      <p class="is-size-6 mt-6 mb-6">
        Fehlt eine Veranstaltung?
        <a href="https://realisiere-deine-idee.de/kontakt">Schreibt uns</a>
        gerne an!
      </p>
    </div>
  </div>
</template>

<script type="text/babel">
import Meta from "@/components/seo/Meta.vue";
import FullPageContent from "@/components/notion/FullPageContent.vue";
import BlockContent from "@/components/notion/BlockContent.vue";
import EventContent from "@/components/notion/EventContent.vue";

export default {
  name: "Uebersicht",
  components: {
    Meta,
    FullPageContent,
    BlockContent,
    EventContent,
  },
  data() {
    return {
      NotionPageID: "301ff67830844c368dfe609d41b9b626",
      NotionEventID1: "598bbbda0def4d92ad90fce19e156d63",
      NotionEventID2: "05dbeb8cbd2f459ea29300502039f3f1",
      title: "Realisiere deine Idee",
      description:
        "TeStUp - Das StartUp-Village für technologische Produkte der Hochschule Flensburg unterstützt beim realisieren von Ideen.",
      data: [],
    };
  },
  methods: {
    apidata: function (value) {
      this.data = value;
    },
    eventmodal: function (value) {
      this.$emit("eventmodal", value);
    },
  },
};
</script>

<style lang="scss"></style>
