<template>
  <div class="eventcontent">
    <div class="columns">
      <div class="column is-12 is-10-desktop is-offset-1-desktop">
        <div class="eventbutton mb-5" v-if="isLoadingEvent">
          <b-skeleton height="30px" width="30%"></b-skeleton>
          <b-skeleton height="20px" width="15%"></b-skeleton>
        </div>
        <div class="eventbutton mb-5" v-if="isLoadingEvent">
          <b-skeleton height="30px" width="15%"></b-skeleton>
          <b-skeleton height="20px" width="12%"></b-skeleton>
        </div>
        <div class="eventbutton mb-5" v-if="isLoadingEvent">
          <b-skeleton height="30px" width="25%"></b-skeleton>
          <b-skeleton height="20px" width="18%"></b-skeleton>
        </div>
        <div class="eventbutton mb-5" v-if="isLoadingEvent">
          <b-skeleton height="30px" width="19%"></b-skeleton>
          <b-skeleton height="20px" width="15%"></b-skeleton>
        </div>
        <transition-group
          name="custom-classes-transition"
          enter-active-class="animate__animated animate__fadeIn animate__slow"
          leave-active-class="animate__animated animate__fadeOut animate__slow"
        >
          <div class="event" v-for="event in data.results" :key="event.id">
            <div class="eventbutton mb-5" @click="openModal(event)">
              <h2
                class="is-size-3 is-size-4-mobile mb-3"
                v-if="event.properties.WebsiteHeadline.rich_text.length != 0"
              >
                {{ event.properties.WebsiteHeadline.rich_text[0].plain_text }}
              </h2>
              <p>
                <span
                  class="is-size-6"
                  v-if="
                    event.properties.Datum.date.end == null &&
                    event.properties.Datum.date.start.length == 10
                  "
                >
                  <!-- nur Startdatum, keine Zeit -->
                  {{ event.properties.Datum.date.start | moment("DD.MM.YYYY") }}
                </span>
                <span
                  class="is-size-6"
                  v-else-if="
                    event.properties.Datum.date.end == null &&
                    event.properties.Datum.date.start.length != 10
                  "
                >
                  <!-- nur Startdatum mit Startzeit -->
                  {{ event.properties.Datum.date.start | moment("DD.MM.YYYY") }}
                  ab
                  {{ event.properties.Datum.date.start | moment("HH:mm") }}
                </span>
                <span
                  class="is-size-6"
                  v-else-if="
                    event.properties.Datum.date.end != null &&
                    event.properties.Datum.date.start.length != 10 &&
                    event.properties.Datum.date.start.slice(0, -19) ==
                      event.properties.Datum.date.end.slice(0, -19)
                  "
                >
                  <!-- nur Startdatum mit Startzeit und Endzeit -->
                  {{ event.properties.Datum.date.start | moment("DD.MM.YYYY") }}
                  von
                  {{ event.properties.Datum.date.start | moment("HH:mm") }} bis
                  {{ event.properties.Datum.date.end | moment("HH:mm") }}
                </span>
                <span
                  class="is-size-6"
                  v-if="
                    event.properties.Datum.date.end != null &&
                    event.properties.Datum.date.end.length == 10
                  "
                >
                  <!-- Startdatum, keine Zeit + Enddatum, keine Zeit -->
                  {{ event.properties.Datum.date.start | moment("DD.MM.YYYY") }}
                  bis
                  {{ event.properties.Datum.date.end | moment("DD.MM.YYYY") }}
                </span>
                <span
                  class="is-size-6"
                  v-if="
                    event.properties.Datum.date.end != null &&
                    event.properties.Datum.date.end.length != 10 &&
                    event.properties.Datum.date.start.slice(0, -19) !=
                      event.properties.Datum.date.end.slice(0, -19)
                  "
                >
                  <!-- Startdatum mit Zeit + Enddatum mit Zeit -->
                  {{
                    event.properties.Datum.date.start | moment("DD.MM.YYYY")
                  }},
                  {{ event.properties.Datum.date.start | moment("HH:mm") }} bis
                  {{ event.properties.Datum.date.end | moment("DD.MM.YYYY") }},
                  {{ event.properties.Datum.date.end | moment("HH:mm") }}
                </span>
                <span
                  class="is-size-6 eventtext"
                  v-if="event.properties.WebsiteOrt.rich_text.length != 0"
                >
                  | {{ event.properties.WebsiteOrt.rich_text[0].plain_text }}
                </span>
              </p>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import axios from "axios";

export default {
  name: "EventContent",
  components: {},
  data() {
    return {
      data: [],
      closenotificationEvent: false,
      isLoadingEvent: true,
      isErrorEvent: false,
      notionpagesize: 25,
    };
  },
  props: {
    notionevent1: String,
    notionevent2: String,
  },
  methods: {
    async getData() {
      try {
        const res = await axios.post(
          ".netlify/functions/readevents",
          JSON.stringify({
            PageID1: this.notionevent1,
            PageID2: this.notionevent2,
            PageSize: this.notionpagesize,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (res.status === 200) {
          this.data = res.data;
          this.isLoadingEvent = false;
        } else {
          //could not get Data
          this.isLoadingEvent = false;
          this.isErrorEvent = true;
        }
      } catch (error) {
        //console.log("ERROR:", error);
        this.isLoadingEvent = false;
        this.isErrorEvent = true;
      }
    },
    openModal(data) {
      this.$emit("eventmodaldata", data);
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
.eventbutton {
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  border-left: 1px solid white;
  transition: all 0.3s ease;
  & h2 {
    transition: all 0.3s ease;
  }
}
.eventbutton:hover {
  padding-left: 3rem;
  border-left: 5px solid white;
  color: $info;
  & h2 {
    color: $info;
  }
}
.eventcontent {
  min-height: 10rem;
}
</style>
